@import './color.scss';
.Service {
  width: 100%;
  display: flex;
  background-color: $gray900;
  .Service__header {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 20vh 17.5%;
    background-position: top center;
    background-size: cover;
    justify-content: flex-end;
    align-items: flex-start;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // backdrop-filter: grayscale(1) brightness(0.35);
    }
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $black;
      filter: brightness(0.35) grayscale(0.5);
      source {
        width: 100%;
        height: 100%;
      }
    }
    .Service__box {
      display: flex;
      align-items: center;
      z-index: 1;

      h1 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 8.333rem;
        line-height: 11.6rem;
        color: $white;
        letter-spacing: -0.9px;
        margin: 0;
        strong {
          color: $primary;
        }
      }
    }
  }
  .Service__nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
    padding: 0 17.5%;
    box-sizing: border-box;
    a {
      text-decoration: none;
      font-family: 'Mohave';
      font-weight: 400;
      font-size: 1.555rem;
      color: #89898c;
    }
  }
  .Service__main {
    width: 100%;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10vh 0;

    .Service__title {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.444rem;
      color: $background;
      margin: 5vh 17.5%;
    }
    .Service__list {
      width: 100%;
      display: flex;
      margin-bottom: 10vh;
      .ServiceCard {
        text-decoration: none;
        border: 0;
        border-top: 1px solid $gray700;
        border-bottom: 1px solid $gray700;
        width: 100%;
        display: inline-flex;
        box-sizing: border-box;
        padding: 3vh 17.5%;
        background-color: transparent;
        transition: background-color 250ms linear, height 250ms linear;
        cursor: pointer;
        &:hover {
          background-color: $primary;
          h1 strong {
            opacity: 1;
            // width: 10rem;
          }
          .ServiceCard__box span {
            opacity: 1;
            display: flex;
          }
        }
        h1 {
          font-family: 'Mohave';
          font-weight: 700;
          font-size: 9.111rem;
          text-align: left;
          color: $white;
          margin: 5rem 0 3rem;
          transition: width 250ms linear;
          strong {
            display: inline-block;
            opacity: 0;
            white-space: pre;
            transition: opacity 250ms linear;
          }
        }
        .ServiceCard__box {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          span {
            font-family: 'Montserrat';
            font-size: 1.555rem;
            line-height: 2.222rem;
            color: $gray200;
            text-align: left;
            white-space: pre-line;
            max-width: 50%;
            opacity: 0;
            display: none;
            min-height: 6rem;
            transition: opacity 150ms linear;
          }
        }
      }
    }
  }
  .Service__favi {
    width: 100%;
    display: flex;

    box-sizing: border-box;
    .ServiceFavi__introduce {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 15vh 17.5% 15vh;
      align-items: center;
      img {
        height: 8rem;
        object-fit: cover;
      }
      h1 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 3.555rem;
        line-height: 4.444rem;
        letter-spacing: -0.9px;
        white-space: pre-line;
        color: $background;
        margin: 5vh 0 0;
      }
    }
    .ServiceFavi__benefit {
      width: 100%;
      min-height: 100vh;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      padding: 10vh 17.5%;
      background-color: $gray0;
      flex-wrap: wrap;
      h1 {
        width: 100%;
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 4rem;
        letter-spacing: -0.9px;
        white-space: pre-line;
        color: $gray900;
        text-align: left;
        margin-bottom: 10vh;
      }
      img {
        width: 50%;
        height: calc(65vw / 2);
        object-fit: cover;
        object-position: center;
        background-color: $gray200;
      }
      .ServiceFavi__box {
        width: calc(65vw / 2);
        display: flex;
        height: calc(65vw / 2);
        align-items: flex-start;
        box-sizing: border-box;
        padding: 3rem 1.5rem;
        justify-content: space-between;
        h3 {
          font-family: 'Mohave';
          font-weight: 700;
          font-size: 3.555rem;
          white-space: pre-line;
          text-align: left;
          line-height: 4.444rem;
          margin: 0;
        }
        span {
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 1.333rem;
          text-align: left;
          line-height: 2rem;
          // margin: 1.5rem 0;
        }
        h5 {
          font-family: 'Mohave';
          font-weight: 600;
          font-size: 2.555rem;
          white-space: pre-line;
          text-align: left;
          line-height: 3rem;
          margin: 0;
        }
      }
    }
    .ServiceFavi__diggin {
      display: flex;
      width: 100%;
      height: 100vh;
      padding: 10vh 0;
      box-sizing: border-box;
      justify-content: space-between;
      div {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 0 17.5%;
        &:first-child {
          justify-content: flex-end;
          text-align: right;
        }
        &:last-child {
          justify-content: flex-start;
          text-align: left;
        }
        span {
          width: 55%;
          font-family: 'Montserrat';
          font-size: 1rem;
          font-weight: 500;
          white-space: pre-line;
          line-height: 1.555rem;
          color: #89898c;
          letter-spacing: -0.5px;
        }
      }
    }
    .ServiceFavi__life {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      background-color: $gray0;
      padding: 10vh 0;
      h1 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 3.555rem;
        line-height: 4.444rem;
        color: $secondary;
        white-space: pre-line;
        letter-spacing: -0.7px;
      }
    }
    .ServiceFavi__keyword {
      position: relative;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      background-color: $gray0;
      justify-content: center;
      // padding: 5vh 0;
      .ServiceFavi__box {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 10vh 0;
        align-items: center;
        overflow: hidden;
        scroll-behavior: smooth;
      }
      .KeywordCard {
        position: relative;
        display: inline-flex;
        flex: none;
        width: 100%;
        box-sizing: border-box;
        padding: 5vh 17.5%;
        // margin: 0 17.5%;
        align-items: flex-start;
        background-position: right center;
        background-size: contain;
        background-repeat: no-repeat;
        img {
          position: absolute;
          right: 20%;
          object-fit: contain;
          height: 110%;
          bottom: 0;
          border: 0.4rem solid $gray800;
          border-radius: 1rem;
        }
        h1 {
          font-family: 'Mohave';
          font-weight: 700;
          font-size: 4rem;
          line-height: 4.444rem;
          text-align: left;
          letter-spacing: -1.2px;
          margin: 0;
        }
        span {
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 1.333rem;
          line-height: 2rem;
          text-align: left;
          width: 35%;
          margin: 5vh 0 10vh;
        }
        a {
          border: 1px solid $primary;
          font-family: 'Montserrat';
          font-size: 1.555rem;
          font-weight: 600;
          color: $primary;
          text-decoration: none;
          padding: 1.5rem 3rem;
          border-radius: 3rem;
          transition: background-color 150ms linear;
          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
      .ServiceFavi__arrow {
        position: absolute;
        border: 0;
        background-color: transparent;
        height: 50%;
        padding: 0 1.5rem;
        cursor: pointer;
        &:disabled {
          display: none;
        }
        &.ServiceFavi__arrow--prev {
          left: 3%;
          img {
            transform: rotate(180deg);
          }
        }
        &.ServiceFavi__arrow--next {
          right: 3%;
        }
        img {
          height: 2.5rem;
        }
      }
    }

    .ServiceFavi__indicator {
      // position: absolute;
      // padding: 3vh 0;
      // bottom: 5vh;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      // background-color: $gray0;

      button {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: $gray200;
        border: 0;
        margin: 0 1rem;
        padding: 0;
        cursor: pointer;
        &.active {
          background-color: $primary;
        }
      }
    }
    .ServiceFavi__feature {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 10vh 17.5%;
      align-items: flex-start;
      min-height: 100vh;
      h1 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 4rem;
        line-height: 4.444rem;
        letter-spacing: -0.9px;
        white-space: pre-line;
        color: $white;
        text-align: left;
      }
      .ServiceFavi__box {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        span {
          width: 17rem;
          color: #ddd;
          font-family: 'Montserrat';
          font-size: 1rem;
          line-height: 1.667rem;
          white-space: pre-line;
          text-align: left;
        }
        .ServiceFavi__list {
          flex: 1;
          width: 100%;
          display: flex;
          box-sizing: border-box;
        }
        .FeatureCard {
          display: inline-flex;
          width: calc((100% - 3rem) / 3);
          border: 1px solid $primary;
          border-radius: 0.5rem;
          box-sizing: border-box;
          padding: 2rem 1rem;
          margin: 0 0.5rem 0.5rem;
          align-items: center;
          .FeatureCard__index {
            font-family: 'Mohave';
            font-size: 1.333rem;
            font-weight: 500;
            color: $primary;
          }
          .FeatureCard__title {
            font-family: 'Mohave';
            font-size: 2.222rem;
            font-weight: 700;
            color: $white;
            margin: 1rem 0 0;
          }
          .FeatureCard__icon {
            width: 70%;
            height: 5rem;
            object-fit: contain;
            color: $lightgray;
            font-size: 0.9rem;
            font-weight: 500;
            margin: 3rem 0;
          }
          .FeatureCard__contents {
            min-height: 15vh;
            font-family: 'Noto Sans';
            font-weight: 400;
            text-align: center;
            width: 100%;
            font-size: 0.9rem;
          }
        }
      }
    }

    .ServiceFavi__video {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      &:hover {
        .play {
          // display: flex;
          opacity: 1;
        }
      }
      video {
        width: 100%;
      }
      .play {
        position: absolute;
        // display: none;
        opacity: 0;
        z-index: 50;
        box-sizing: border-box;
        background-color: transparent;
        padding: 0.5rem;
        border: 0;
        // border: 1px solid $gray500;
        border-radius: 50%;
        // width: 4.5rem;
        // height: 4.5rem;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: opacity 150ms linear;
        img {
          height: 5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .Service {
    .Service__header {
      padding: 15vh 5%;
      .Service__box {
        h1 {
          text-align: left;
        }
      }
    }
    .Service__main {
      padding: 5vh 0;
      .Service__title {
        margin: 0 5% 5vh;
      }
      .Service__list {
        .ServiceCard {
          padding: 3vh 5%;
          h1 {
            font-size: 6.111rem;
          }
          .ServiceCard__box {
            span {
              max-width: 100%;
            }
          }
        }
      }
    }
    .Service__favi {
      .ServiceFavi__introduce {
        padding: 5vh 5%;
      }
      .ServiceFavi__diggin {
        height: auto;
        div {
          padding: 0 5%;
        }
      }
      .ServiceFavi__life {
        padding: 5vh 0;
        .Service__nav {
          padding: 0 5%;
        }
      }
      .ServiceFavi__keyword {
        .ServiceFavi__box {
          padding: 5vh 0;
          // flex-direction: column;
          .KeywordCard {
            padding: 0vh 15%;
            img {
              display: none;
              position: relative;
              width: 60%;
            }
            span {
              width: 100%;
            }
          }
        }
      }
      .ServiceFavi__indicator {
        padding: 3vh 5%;
        box-sizing: border-box;
      }
      .ServiceFavi__benefit {
        padding: 5vh 5% 15vh;
        min-height: auto;
        h1 {
          margin-bottom: 5vh;
        }
        img {
          height: 45vw;
        }
        .ServiceFavi__box {
          width: 45vw;
          height: 45vw;
          padding: 1rem;
          h3 {
            font-size: 2.888rem;
            line-height: 3.333rem;
          }
          h5 {
            font-size: 1.777rem;
            line-height: 2.222rem;
          }
          span {
            display: none;
          }
        }
      }
      .ServiceFavi__feature {
        padding: 5vh 5%;
        min-height: auto;
        .ServiceFavi__box {
          flex-direction: column;
          span {
            margin-bottom: 5vh;
          }
          .ServiceFavi__list {
            flex-direction: column;

            .FeatureCard {
              width: 100%;
              margin: 0 0 3rem;
              align-items: center;
              .FeatureCard__index {
                display: none;
              }
              .FeatureCard__contents {
                min-height: auto;
              }
            }
          }
        }
      }
    }
  }
}
