@import './color.scss';
.Home {
  width: 100%;
  height: auto;
  background-color: $gray900;
  h1 {
    font-family: 'Mohave';
    margin: 0;
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.444rem;
    color: $secondary;
    white-space: pre-line;
    letter-spacing: -1.444px;
  }
  .Home__header {
    position: relative;
    width: 100%;
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    padding: 20vh 17.5%;
    align-items: flex-start;
    justify-content: flex-end;
    background-position: top right;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // z-index: 0;
      // backdrop-filter: grayscale(1) brightness(0.25);
    }
    .Home__box {
      display: flex;
      width: 100%;
      align-items: flex-start;
      z-index: 1;
      hr {
        width: 100%;
        height: 1px;
        background-color: $gray500;
        border: 0;
        margin: 1.5rem 0;
      }
      h1 {
        font-family: 'Mohave';
        font-size: 8.333rem;
        color: $white;
        line-height: 11.667rem;
        strong {
          color: $primary;
        }
      }
    }
  }
  .Home__catchphrase {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    background-color: $gray0;
    .Home__nav {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      span {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 1.555rem;
        color: $gray500;
        letter-spacing: -0.4px;
      }
    }
    .Home__box {
      position: relative;
      display: flex;
      width: 100%;
      margin: 10vh 0;
      align-items: center;
      justify-content: center;
      img {
        position: absolute;
        width: 18rem;
        height: 18rem;
        object-fit: contain;
        object-position: center center;
        z-index: 0;
        // opacity: 0.15;
        // filter: ;
      }
      h1 {
        z-index: 1;
        font-family: 'Mohave';
        font-size: 4rem;
        font-weight: 700;
        line-height: 4.444rem;
        color: $secondary;
      }
    }
  }
  .Home__about {
    width: 100%;
    min-height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    background-color: $gray0;
    h5 {
      font-family: 'Mohave';
      margin: 0 0 1rem;
      font-weight: 500;
      font-size: 1.556rem;
      color: $primary;
      white-space: pre-line;
      letter-spacing: -0.56px;
    }
    span {
      font-family: 'Montserrat';
      font-size: 1.333rem;
      font-weight: 400;
      line-height: 2rem;
      color: $secondary;
      text-align: left;
      white-space: pre-line;
      margin-top: 1rem;
    }
    .Home__box {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 10vh 0;
      .PhotoCard {
        width: 50%;
        height: calc(65vw / 2);
        position: relative;
        display: flex;
        align-items: center;
        transition: transform 150ms ease-in-out;

        &:first-child {
          transform: rotate(-15deg);
          &:hover {
            transform: translate(5%, -5%) rotate(-15deg);
          }
        }
        &:last-child {
          transform: rotate(15deg) translate(-10%, -25%);
          z-index: 1;
          &:hover {
            transform: rotate(15deg) translate(-5%, -30%);
          }
        }
        .PhotoCard__cover {
          width: 100%;
          height: 100%;
          background-color: $gray200;
          object-fit: cover;
          object-position: center;
        }
        .PhotoCard__sticker {
          position: absolute;
          left: 2rem;
          bottom: 2rem;
          width: 15rem;
          height: 15rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $primary;
          h3 {
            font-family: 'Mohave';
            font-weight: 500;
            font-size: 3.555rem;
            line-height: 3.888rem;
            color: $white;
            margin: 1rem 0;
            letter-spacing: -1.28px;
          }
        }
        .PhotoCard__sticker2 {
          position: absolute;
          width: 100%;
          // height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0rem 0;
          // border-radius: 50%;
          background-color: $primary;
          overflow: hidden;
          img {
            margin: 0 1.5rem;
          }
          h3 {
            font-family: 'Mohave';
            font-weight: 500;
            font-size: 3.555rem;
            line-height: 3.555rem;
            // align-items: baseline;
            color: $white;
            margin: 1rem 0;
            letter-spacing: -1.28px;
            white-space: pre;
          }
        }
      }
    }
  }
  .Home__diggin {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 20vh 17.5%;
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 12.222rem;
      line-height: 11.111rem;
      color: #3e3e43;
      width: 100%;
      text-align: left;
      white-space: pre-line;
    }
    .Home__box {
      width: 100%;
      display: flex;
      align-items: flex-end;
      a {
        font-family: 'Montserrat';
        font-size: 1.555rem;
        font-weight: 500;
        color: $primary;
        padding: 1.5rem 3rem;
        border: 1px solid $primary;
        background-color: transparent;
        transition: background-color 150ms linear;
        text-decoration: none;
        border-radius: 3rem;
        margin: 5vh 0;
        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
      span {
        width: 50%;
        text-align: right;
        font-family: 'Montserrat';
        font-size: 1.333rem;
        line-height: 2rem;
        color: $background;
      }
    }
  }
  .Home__news {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 10vh 0;
    h5 {
      font-family: 'Mohave';
      font-weight: 500;
      font-size: 1.555rem;
      color: $white;
      letter-spacing: -0.5px;
      margin: 0;
    }
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.444rem;
      color: $white;
      margin: 5vh 0;
    }
    nav {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 5vh 17.5%;
      align-items: center;
      justify-content: space-around;
      button {
        font-family: 'Mohave';
        font-weight: 500;
        font-size: 1.555rem;
        color: $gray500;
        border: 0;
        background-color: transparent;
        border-bottom: 1.5px solid $gray500;
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: all 150ms linear;
        &.active {
          color: $background;
          border-color: $background;
        }
      }
    }
    .Home__list {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 5vh 17.5%;
      .NewsCard {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        height: calc(65vw / 2);
        .NewsCard__box {
          position: relative;
          flex: 1;
          box-sizing: border-box;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 3rem;
          width: 100%;
          height: 100%;
          h1 {
            font-family: 'Mohave';
            font-size: 4rem;
            color: $primary;
            margin: 0;
          }
          span {
            font-family: 'Montserrat';
            font-size: 1.555rem;
            line-height: 2.222rem;
            color: $white;
            white-space: pre-line;
            text-align: left;
            margin: 5rem 0;
          }
          a {
            font-family: 'Montserrat';
            color: $background;
            // padding: 1rem 2rem;
            font-size: 1.555rem;
          }
          button {
            display: flex;
            position: absolute;
            width: 90%;
            height: 90%;
            border: 0;
            padding: 0;
            background-color: transparent;
            cursor: pointer;
            transition: all 150ms linear;
            &::after {
              content: 'INTERVIEW';
              font-family: 'Mohave';
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
              font-size: 4rem;
              color: $background;
              width: 100%;
              height: 100%;
              z-index: 10;
              background-color: rgba(0, 0, 0, 0.3);
              backdrop-filter: grayscale(1) brightness(0.7);
            }
            &:hover {
              z-index: 5 !important;
              &:nth-child(2n) {
                transform: rotate(10deg) scale(1.1) !important;
              }
              &:nth-child(2n + 1) {
                transform: rotate(-5deg) scale(1.1) !important;
              }
              img {
              }
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            box-sizing: border-box;
            background-color: $gray900;
            // padding: 3rem;
          }
        }
      }
    }
  }
  .Home__partner {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5vh 0;
    .Home__box {
      display: flex;
      width: 100%;
      padding: 5vh 17.5%;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-between;
      span {
        font-family: 'Montserrat';
        text-align: left;
        white-space: pre-line;
        color: $background;
        font-size: 1.333rem;
        line-height: 2rem;
      }
      a {
        text-decoration: none;
        font-weight: 600;
        color: $primary;
        font-size: 1.555rem;
        font-family: 'Montserrat';
        border: 1px solid $primary;
        padding: 1.5rem 3rem;
        border-radius: 3rem;
        margin-top: 1rem;
        cursor: pointer;
        transition: background-color 150ms linear;
        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
    }
    .Main__repeat {
      strong {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $white;
        color: $gray900;
      }
    }
    .Home__list {
      padding: 0vh 5%;
      overflow: hidden;
      display: flex;
      width: 100%;
      box-sizing: border-box;
      .Home__logo {
        display: flex;
        flex: none;
        width: 17rem;
        height: 17rem;
        border: 1px solid $background;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 3.5rem;
        margin: 0 3rem;
        img {
          flex: none;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .Home__contact {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 10vh 17.5%;
    box-sizing: border-box;
    background-color: $gray0;
    justify-content: center;
    align-items: center;
    h1 {
      font-family: 'Mohave';
      font-weight: 500;
      font-size: 11.111rem;
      line-height: 11.111rem;
      letter-spacing: -4px;
      margin: 0;
    }
    span {
      font-family: 'Montserrat';
      font-size: 2.222rem;
      line-height: 3.333rem;
      text-align: center;
      white-space: pre-line;
      color: $secondary;
      margin: 5rem 0;
    }
    a {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 1.555rem;
      padding: 1.5rem 3rem;
      border: 1px solid $primary;
      border-radius: 3rem;
      color: $primary;
      background-color: transparent;
      text-decoration: none;
      transition: background-color 150ms linear;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .Home {
    .Home__header {
      padding: 25vh 5%;
    }
    .Home__catchphrase {
      padding: 5vh 7.5%;
      .Home__box {
        margin: 10vh 0 5vh;
      }
    }
    .Home__about {
      padding: 5vh 5%;
      min-height: auto;
      .Home__box {
        padding: 5vh 5% 0;
        flex-direction: column;
        .PhotoCard {
          width: 100%;
          height: 90%;
          margin-bottom: 10vh;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .Home__diggin {
      padding: 5vh 5%;
      .Home__box {
        span {
          width: 100%;
        }
      }
    }
    .Home__news {
      padding: 5vh 0;
      nav {
        padding: 5vh 5%;
        button {
          font-size: 2rem;
        }
      }
      .Home__list {
        padding: 5vh 5%;
        .NewsCard {
          flex-direction: column-reverse;
          height: auto;
          .NewsCard__box {
            width: 90%;
            height: 80vw;
            flex: none;
            &:first-child {
              padding: 0;
            }
          }
        }
      }
    }
    .Home__partner {
      padding: 0 0 5vh;
      .Home__box {
        padding: 0 5% 5vh;
        flex-direction: column;
        a {
          margin-top: 5vh;
        }
      }
      .Home__list {
        justify-content: space-between;
        flex-wrap: wrap;
        .Home__logo {
          width: 12rem;
          height: 12rem;
          margin: 0 0 2rem;
          padding: 2.5rem;
        }
      }
    }
    .Home__contact {
      padding: 10vh 5%;
      height: auto;
      h1 {
        font-size: 8rem;
        line-height: 8rem;
      }
      span {
        text-align: left;
      }
    }
  }
}
