@import './color.scss';
.News {
  width: 100%;
  display: flex;
  background-color: $gray900;
  .News__header {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      // background-color: rgba(0, 0, 0, 0.2);
      z-index: 0;
      // backdrop-filter: grayscale(1) brightness(0.5);
    }
    img {
      position: absolute;
      left: -3rem;
      bottom: 15vh;
      width: 15.111rem;
      height: 15.111rem;
      border-radius: 50%;
      border: 1px solid $primary;
      box-sizing: border-box;
      padding: 6rem;
      object-fit: contain;
      transform: rotate(90deg);
    }
    // filter: grayscale(1);
    hr {
      width: 100%;
      height: 1px;
      background-color: $gray300;
      border: 0;
      z-index: 1;
    }
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 8.333rem;
      line-height: 11.6rem;
      letter-spacing: -0.9px;
      color: $white;
      margin: 0;
      z-index: 1;
      strong {
        font-weight: inherit;
        color: $primary;
      }
    }
  }
  .News__main {
    display: flex;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.444rem;
      color: $background;
      letter-spacing: -0.5px;
      margin: 10vh 0 1vh;
    }
    h5 {
      // font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 1.333rem;
      line-height: 1.778rem;
      color: $background;
      letter-spacing: -0.5px;
      text-align: left;
      white-space: pre-line;
    }
    .News__nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 1.555rem;
        color: #89898c;
      }
    }
    .News__list {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 5vh 0;
      .NewsCard {
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding-bottom: 3rem;
        border-bottom: 1px solid $gray700;
        margin-bottom: 3rem;
        text-decoration: none;
        .NewsCard__cover {
          width: 30rem;
          height: 20rem;
          background-color: $gray800;
          object-fit: cover;
          object-position: center;
        }
        .NewsCard__box {
          flex: 1;
          height: 20rem;
          display: inline-flex;
          align-items: flex-start;
          box-sizing: border-box;
          padding: 1rem 5%;
          justify-content: space-between;
          .NewsCard__key {
            font-family: 'Mohave';
            font-size: 1.555rem;
            color: $primary;
          }
          .NewsCard__title {
            font-family: 'Mohave';
            font-weight: 500;
            font-size: 2.667rem;
            color: $background;
            text-align: left;
            margin-top: 1rem;
          }
          .NewsCard__date {
            font-size: 1.333rem;
            color: $gray300;
            font-weight: 400;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
.NewsInfo {
  width: 100%;
  display: flex;
  background-color: $gray900;
  .NewsInfo__main {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background-color: $gray0;
    .NewsInfo__header {
      width: 100%;
      box-sizing: border-box;
      padding: 20vh 17.5% 5vh;
      h5 {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 1.555rem;
        color: $secondary;
        margin: 0;
      }
      h3 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 4.888rem;
        color: $secondary;
        letter-spacing: -0.1rem;
        line-height: 6rem;
        white-space: pre-line;
      }
    }
    .NewsInfo__cover {
      width: 100%;
      height: auto;
      height: 50vh;
      background-color: $gray500;
      object-fit: contain;
      object-position: center center;
    }
    .NewsInfo__footer {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 10vh 17.5%;
      .NewsInfo__contents {
        font-family: 'Montserrat';
        font-size: 1.333rem;
        font-weight: 500;
        line-height: 1.778rem;
        text-align: left;
        white-space: pre-line;
        color: $gray900;
      }
      .NewsInfo__news {
        font-family: 'Mohave';
        font-size: 1.555rem;
        font-weight: 500;
        text-align: left;
        margin-top: 10vh;
        color: $gray900;
        letter-spacing: -0.5px;
      }
    }
    .NewsInfo__button {
      display: inline-block;
      background-color: $primary;
      border: 0;
      border-radius: 3rem;
      padding: 1.5rem 3rem;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 1.555rem;
      color: $background;
      text-decoration: none;
      margin-bottom: 10vh;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 840px) {
  .News {
    .News__header {
      padding: 10vh 5%;
      h1 {
        font-size: 6.333rem;
        text-align: left;
      }
    }
    .News__main {
      padding: 5vh 5%;
      height: auto;
      .News__list {
        .NewsCard {
          flex-direction: column;
          .NewsCard__cover {
            width: 100%;
          }
        }
      }
    }
  }
  .NewsInfo {
    .NewsInfo__main {
      .NewsInfo__header {
        padding: 10vh 5%;
        h3 {
          font-size: 4rem;
          margin: 3rem 0 0;
        }
      }
      .NewsInfo__footer {
        padding: 5vh 5%;
      }
    }
  }
}
