@import './color.scss';
.AboutUs {
  display: flex;
  width: 100%;
  height: auto;
  background-color: $gray900;
  .AboutUs__welcome {
    display: flex;
    height: 100vh;
    background-position: top left;
    background-size: cover;
    // filter: grayscale(10);
    .AboutUs__box {
      align-items: flex-start;
      justify-content: center;
      display: flex;
      box-sizing: border-box;
      padding: 15vh 17.5%;
      width: 100%;
      // height: 100vh;
      background-image: linear-gradient(
        0deg,
        rgba(1, 1, 1, 0.83) 10%,
        transparent
      );
      h1 {
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 11.667rem;
        letter-spacing: -0.8px;
        color: $secondary;
        margin: 0;
        line-height: 13.333rem;
      }
      h3 {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 11.111rem;
        letter-spacing: -0.8px;
        color: $secondary;
        margin: 0;
        line-height: 9rem;
      }
      span {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.444rem;
        text-align: left;
        width: 50%;
        margin-left: 50%;
        white-space: pre-line;
        margin-top: 5vh;
        strong {
          font-weight: 400;
          text-decoration: underline;
        }
      }
      div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .AboutUs__category {
    width: 100%;
    height: 100vh;
    display: flex;
    margin-top: 10vh;
    .AboutUsCategory {
      position: relative;
      display: flex;
      flex: 0 15vw;
      background-color: $gray900;
      color: $white;
      border: 0;
      padding: 0;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      &:first-child {
        background-color: $white;
        // color: $gray900;
        .AboutUsCategory__index,
        .AboutUsCategory__key {
          color: $gray900;
        }
      }
      &:nth-child(3) {
        background-color: $primary;
      }
      &.AboutUsCategory--select {
        flex: 1;
        .AboutUsCategory__image,
        .AboutUsCategory__title,
        .AboutUsCategory__box,
        .AboutUsCategory__contents,
        .AboutUsCategory__key {
          display: flex;
        }
        .AboutUsCategory__index {
          position: absolute;
          font-size: 11.111rem;
          color: $background;
          top: 1rem;
          left: 1rem;
          // transform: translate(-23rem, -25rem);
        }
        .AboutUsCategory__title {
          position: absolute;
          transform: translate(0, -13rem);
        }
        .AboutUsCategory__contents {
          position: absolute;
          // font-size: 2.5rem;
          transform: translate(0, -6rem);
        }
        .AboutUsCategory__key {
          position: absolute;
          // transform: translate(0, 10rem);
          bottom: 0rem;
          font-size: 23.888rem;
          line-height: 23rem;
          letter-spacing: -0.6rem;
        }
      }
      &:hover:not(.AboutUsCategory--select) {
        .AboutUsCategory__index {
          position: absolute;
          top: 3rem;
          left: 1rem;
          // transform: translate(-4rem, -25rem);
          font-size: 3.555rem;
          color: $background;
        }
        .AboutUsCategory__box,
        .AboutUsCategory__image,
        .AboutUsCategory__key {
          display: flex;
        }
      }
      .AboutUsCategory__image,
      .AboutUsCategory__title,
      .AboutUsCategory__contents,
      .AboutUsCategory__key {
        display: none;
      }
      .AboutUsCategory__box {
        display: none;
        box-sizing: border-box;
        padding: 0 5%;
        width: 100%;
        flex: 1;
        overflow: hidden;
      }
      .AboutUsCategory__image {
        position: relative;
        top: 0;
        width: 100%;
        height: 50vh;
        background-color: #ddd;
        object-fit: cover;
        object-position: center;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: 50vh;
          background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.7) 10%,
            transparent
          );
        }
      }
      .AboutUsCategory__index {
        font-family: 'Mohave';
        font-weight: 600;
        font-size: 1.777rem;
        transition: transform 150ms linear;
      }
      .AboutUsCategory__key {
        font-family: 'Mohave';
        font-weight: 600;
        font-size: 6.222rem;
        width: 100%;
        color: $lightgray;
        letter-spacing: -2.2px;
      }
      .AboutUsCategory__title {
        font-family: 'Mohave';
        font-weight: 600;
        font-size: 4rem;
        letter-spacing: -1.44px;
        color: $background;
        width: 100%;
      }
      .AboutUsCategory__contents {
        // font-family: 'DoHyeon';
        font-family: 'Mohave';
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.333rem;
        width: 100%;
        white-space: pre-line;
        text-align: left;
      }
    }
  }

  .AboutUs__culture {
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    div {
      flex: 1;
      border-right: 1px solid $gray800;
      width: 100%;
      box-sizing: border-box;
      display: inline-flex;
      padding: 0 3%;
      justify-content: center;
      align-items: flex-start;
      white-space: pre-line;
      &:last-child {
        flex: 1.3;
      }
      h3 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 3.111rem;
        color: $background;
        text-align: left;
        line-height: 4.444rem;
        margin: 0;
        letter-spacing: -0.4px;
        &:last-child {
          color: #89898c;
          margin-top: 2.667rem;
        }
      }
      span {
        font-family: 'Montserrat';
        color: $lightgray;
        font-size: 1.333rem;
        line-height: 2rem;
        text-align: left;
        white-space: pre-line;
        &:last-child {
          margin-top: 3rem;
        }
        strong {
          font-weight: 500;
          text-decoration: underline;
          margin: 0 0.7rem;
        }
      }
    }
  }
  .AboutUs__evolution {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 10vh 17.5% 40vh;
    border-top: 1px solid $gray800;
    h1 {
      position: absolute;
      bottom: 15vh;
      right: 1rem;
      font-family: 'Mohave';
      font-weight: 600;
      font-size: 27.777rem;
      color: $white;
      margin: 0;
      line-height: 15rem;
      letter-spacing: -0.4rem;
    }
    .AboutUs__header {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      h3 {
        font-family: 'Mohave';
        font-weight: 600;
        font-size: 4rem;
        line-height: 4.444rem;
        color: $background;
        text-align: left;
        white-space: pre-line;
        margin: 0;
      }
      span {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 1.333rem;
        color: $lightgray;
        text-align: left;
        white-space: pre-line;
        line-height: 2rem;
      }
    }
    .AboutUs__history {
      display: flex;
      width: 100%;
      padding: 10vh 0;

      .AboutUsHistory {
        display: flex;
        padding: 0 1%;
        width: 100%;
        box-sizing: border-box;
        align-items: flex-start;
        .AboutUsHistory__date {
          font-family: 'Mohave';
          width: 100%;
          font-weight: 500;
          font-size: 2.444rem;
          line-height: 3rem;
          color: $background;
          text-align: left;
          border-bottom: 1.5px solid $gray700;
          margin: 0;
        }
        .AboutUsHistory__box {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-top: 2rem;
          .AboutUsHistory__month {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 1.222rem;
            line-height: 1.333rem;
            color: $background;
            margin-bottom: 1rem;
          }
          .AboutUsHistory__contents {
            font-family: 'Montserrat';
            font-size: 1rem;
            font-weight: 500;
            color: $background;
            text-align: left;
            line-height: 1.667rem;
            letter-spacing: -0.4px;
          }
        }
      }
    }
  }
  .AboutUs__crew {
    display: flex;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    h3 {
      font-family: 'Mohave';
      font-weight: 500;
      font-size: 4rem;
      color: $background;
      letter-spacing: -0.1rem;
    }
    .CrewBox {
      display: inline-flex;
      width: 100%;
      box-sizing: border-box;
      padding: 5vh 0;
      &.CrewBox--ceo {
        position: relative;
        align-items: center;
        &::before {
          content: '';
          width: 18rem;
          height: 18rem;
          border-radius: 50%;
          background-color: $gray500;
        }
        .CrewBox__profile {
          position: absolute;
          width: 18rem;
          min-height: 15rem;
          // left: 1rem;
          top: 3%;

          object-fit: contain;
          // &::before {
          //   width: 18.888rem;
          //   height: 18.888rem;
          // }
        }
        .CrewBox__box {
          flex: 1;
          margin-left: 5%;
          align-items: flex-start;
          overflow: hidden;
          &::before {
            display: none;
          }
        }
        .CrewBox__name {
          font-size: 1.777rem;
          font-weight: 500;
        }
      }

      .CrewBox__profile {
        position: absolute;
        // overflow: visible;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 12.222rem;
        min-height: 12.222rem;
        z-index: 1;
        bottom: 30%;
        object-fit: contain;

        font-family: 'Mohave';
        font-weight: 700;
        color: $gray400;
        font-size: 4rem;
        line-height: 12.222rem;
        text-align: left;
        &.CrewBox__profile--error {
          width: 8rem;
        }
        // &::before {
        //   content: '';
        //   width: 12.222rem;
        //   height: 12.222rem;
        //   margin: 0;
        //   position: absolute;
        //   border-radius: 50%;
        //   background-color: $gray500;
        // }
      }
      .CrewBox__list {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 5rem 0;
        .CrewBox__box {
          flex: none;
          padding: 0 3rem;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      .CrewBox__box {
        // flex: 1;
        // width: 100%;
        position: relative;
        display: inline-flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: baseline;
        &::before {
          content: '';
          width: 12.222rem;
          height: 12.222rem;
          border-radius: 50%;
          background-color: $gray500;
        }
      }
      .CrewBox__team {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 16.667rem;
        line-height: 17rem;
        color: $primary;
      }
      .CrewBox__position {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 1.333rem;
        line-height: 1.777rem;
        color: $primary;
        margin-top: 1.5rem;
      }
      .CrewBox__name {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 1rem;
        color: $lightgray;
        margin-top: 1rem;
      }
      .CrewBox__description {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 1.555rem;
        line-height: 2.222rem;
        color: $gray500;
        margin-top: 1rem;
        text-align: left;
        white-space: pre-line;
      }
    }
  }
  .AboutUs__team {
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: center;
    justify-content: center;
    background-color: $gray0;
    h5 {
      margin: 0;
      font-family: 'Montserrat';
      font-size: 2.667rem;
      line-height: 4.444rem;
      font-weight: 500;
      color: $gray900;
      strong {
        font-weight: 500;
        color: $primary;
      }
    }
  }
  .AboutUs__partner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    justify-content: center;
    h3 {
      font-family: 'Mohave';
      font-weight: 600;
      font-size: 4rem;
      color: $background;
      letter-spacing: -0.1rem;
    }
    .AboutUs__box {
      position: relative;
      width: 100%;
      display: flex;
      overflow: hidden;
      box-sizing: border-box;
      padding: 10vh 0;
      scroll-behavior: smooth;
      .PartnerCard {
        width: calc(100% / 3);
        flex: none;
        box-sizing: border-box;
        padding: 0 1rem;
        display: inline-flex;
        border: 0;
        background-color: transparent;
        .PartnerCard__cover {
          width: 100%;
          height: 14rem;
          background-color: $black;
          box-sizing: border-box;

          padding: 2rem;
          border-radius: 0.5rem;
          object-fit: contain;
        }
        .PartnerCard__name {
          margin: 2rem 0 1rem;
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 1.111rem;
          color: $background;
          text-align: center;
          width: 100%;
        }
        .PartnerCard__description {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 1rem;
          color: $background;
          text-align: center;
          line-height: 1.777rem;
        }
      }
    }
    .AboutUs__arrow {
      border: 0;
      height: 20rem;
      margin-top: 5rem;
      background-color: transparent;

      position: absolute;
      cursor: pointer;
      &:disabled {
        display: none;
      }
      &.AboutUs__arrow--prev {
        left: 5%;
        transform: rotate(180deg);
      }
      &.AboutUs__arrow--next {
        right: 5%;
      }
      img {
        height: 3rem;
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .AboutUs {
    .AboutUs__welcome {
      .AboutUs__box {
        padding: 15vh 5%;
        justify-content: flex-start;
        h1,
        h3 {
          text-align: left;
          font-size: 8rem;
          line-height: 11rem;
        }
        div {
          flex-direction: column;
          align-items: flex-start;
        }
        span {
          width: 100%;
          margin-left: auto;
        }
      }
    }
    .AboutUs__category {
      flex-direction: column;
      margin: 0;
      .AboutUsCategory {
        flex: 0 1 20vw;
        &.AboutUsCategory--select .AboutUsCategory__key {
          font-size: 15rem;
        }
      }
    }
    .AboutUs__culture {
      padding: 5vh 5%;
      flex-direction: column;
    }
    .AboutUs__evolution {
      padding: 5vh 5% 10vh;
      h1 {
        bottom: 0rem;
        font-size: 15rem;
      }
      .AboutUs__header {
        flex-direction: column;
        span {
          margin-top: 3rem;
        }
      }
      .AboutUs__history {
        // width: auto;
        // overflow: auto hidden;
        padding: 5vh 0;
        flex-direction: column;
        .AboutUsHistory {
          // width: 85vw !important;
          flex: none;
          margin-bottom: 5vh;
        }
      }
    }
    .AboutUs__crew {
      padding: 5vw 5%;
      .CrewBox {
        padding: 0rem 0;
        &.CrewBox--ceo {
          .CrewBox__profile {
            top: auto;
          }
        }
        .CrewBox__team {
          font-size: 9rem;
          letter-spacing: -0.3rem;
        }
        .CrewBox__list {
          flex-wrap: wrap;
          justify-content: space-around;
          box-sizing: border-box;
          padding: 0 5%;
          .CrewBox__box {
            padding: 0;
            margin-bottom: 3rem;
          }
        }
      }
    }
    .AboutUs__team {
      padding: 10vh 5%;
      height: auto;
      h5 {
        text-align: left;
      }
    }
    .AboutUs__partner {
      padding: 10vh 5%;
      height: auto;
      h3 {
        margin: 0;
      }
      .AboutUs__box {
        padding: 5vh 0 0;
        flex-wrap: wrap;
        .PartnerCard {
          width: 50%;
          margin-bottom: 2rem;
          text-decoration: none;
          .PartnerCard__cover {
            background-color: transparent;
            padding: 1rem;
          }
          .PartnerCard__description {
            display: none;
          }
        }
      }
    }
  }
}
