$black: #000;
$white: #fff;
$primary: #7c6dbe;
$lightgray: #ddd;
$gray900: #1f1f25;
$gray800: #242330;
$gray700: #3d3b47;
$gray600: #55545e;
$gray500: #86858c;
$gray400: #b6b6ba;
$gray300: #ceced1;
$gray200: #e7e7e8;
$gray100: #ededef;
$gray0: #f5f5f7;

$secondary: #1f1f25;
$background: #f3f3f3;
