@import './color.scss';
.ContactUs {
  width: 100%;
  display: flex;
  background-color: $gray900;
  .ContactUs__header {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 20vh 17.5%;
    justify-content: flex-end;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // backdrop-filter: grayscale(1) brightness(0.2);
    }
    img {
      position: absolute;
      left: -3rem;
      bottom: 15vh;
      width: 15.111rem;
      height: 15.111rem;
      border-radius: 50%;
      border: 1px solid $primary;
      box-sizing: border-box;
      padding: 6rem;
      object-fit: contain;
      transform: rotate(90deg);
    }
    .ContactUs__box {
      z-index: 1;
      width: 100%;
      display: flex;
      // height: 100%;
      h1 {
        margin: 0;
        font-family: 'Mohave';
        font-weight: 700;
        font-size: 8.333rem;
        text-align: left;
        color: $white;
        letter-spacing: -0.9px;
        line-height: 11.6rem;
        strong {
          color: $primary;
        }
      }
      hr {
        width: 100%;
        height: 1px;
        border: 0;
        background-color: $gray500;
      }
    }
  }
  .ContactUs__contact {
    display: flex;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: flex-start;
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.444rem;
      color: $background;
      letter-spacing: -0.7px;
      margin: 10vh 0 5vh;
    }
    .ContactUs__nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        font-family: 'Mohave';
        font-weight: 400;
        font-size: 1.555rem;
        color: #89898c;
      }
    }
    .ContactUs__wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .ContactUs__input {
        width: calc((100% - 2rem) / 2);
        background-color: transparent;
        border: 1px solid $gray400;
        border-radius: 0.5rem;
        box-sizing: border-box;
        padding: 1.5rem 3%;
        margin: 0 1rem 2.5rem;
        font-size: 1.555rem;
        color: $white;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:hover,
        &:focus {
          outline: 0;
          border-color: $primary;
        }
      }
      .ContactUs__textarea {
        width: 100%;
        box-sizing: border-box;
        padding: 1.5rem 3%;
        border: 1px solid $gray400;
        border-radius: 0.5rem;
        background-color: transparent;
        min-height: 23.5rem;
        line-height: 1.5rem;
        resize: none;
        color: $white;
        font-family: 'Roboto';
        font-size: 1.555rem;
        &:hover,
        &:focus {
          outline: 0;
          border-color: $primary;
        }
      }
    }
    .ContactUs__footer {
      width: 100%;
      box-sizing: border-box;
      padding: 5vh 0 0;
      display: flex;
      align-items: center;
      .ContactUs__checkbox {
        border: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0.9rem 0;
        font-family: 'Montserrat';
        font-weight: 300;
        font-size: 1.333rem;
        color: $white;
        cursor: pointer;
        img {
          width: 1.7rem;
          height: 1.7rem;
          margin-right: 1rem;
          object-fit: contain;
        }
      }
      .ContactUs__submit {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 1.555rem;
        border: 1px solid $primary;
        border-radius: 3rem;
        padding: 1.5rem 7rem;
        background-color: transparent;
        color: $primary;
        margin: 5vh 0;
        cursor: pointer;
        transition: all 150ms linear;
        &:disabled {
          background-color: $gray600;
          border-color: $gray600;
          color: $gray900;
          cursor: auto;
        }
        &:hover:not(:disabled) {
          background-color: $primary;
          color: $background;
        }
      }
    }
  }
  .ContactUs__location {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    padding: 5vh 17.5%;
    align-items: flex-start;
    h1 {
      font-family: 'Mohave';
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.444rem;
      color: $background;
      letter-spacing: -0.7px;
      margin: 10vh 0 5vh;
    }
    .ContactUs__arrow {
      position: absolute;
      background-color: transparent;
      border: 0;
      height: 50%;
      padding: 1.5rem 1.5rem;
      cursor: pointer;
      transition: background-color 150ms linear;
      &:disabled {
        display: none;
      }
      &:hover {
        background-color: $gray800;
      }
      img {
        height: 2rem;
      }
      &.ContactUs__arrow--prev {
        left: 2%;
        img {
          transform: rotate(180deg);
        }
      }
      &.ContactUs__arrow--next {
        right: 2%;
      }
    }
    .ContactUs__list {
      // position: relative;
      width: 100%;
      display: flex;
      overflow: hidden;
      justify-content: flex-start;
      align-items: center;
      scroll-behavior: smooth;

      .ContactOffice {
        flex: none;
        width: 65vw;
        display: inline-flex;
        .ContactOffice__box {
          width: 50%;
          display: inline-flex;
          align-items: flex-start;
          padding: 10vh 0;
          h5 {
            margin: 3rem 0 0;
            font-family: 'Montserrat';
            // font-family: 'Roboto Condensed';
            font-weight: 500;
            font-size: 1.555rem;
            color: $background;
          }
          span {
            font-family: 'Montserrat';
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.555rem;
            color: $background;
            text-align: left;
            white-space: pre-line;
            margin-top: 1rem;
          }
        }
      }
      .ContactOffice__map {
        position: absolute;
        // flex: 1.5;
        right: 17.5%;
        width: calc(65vw * 0.55);
        height: calc(65vw * 0.36);
        box-sizing: border-box;
        // padding: 0 5%;
        div {
          // width: 100%;
          height: 100%;
          // min-height: 40vh;
          // box-sizing: border-box;
        }
      }
    }
    .ContactUs__nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10vh 0 0;
      button {
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid $gray300;
        padding: 0.7rem 5rem 0.7rem 1rem;
        font-family: 'Mohave';
        font-size: 1.555rem;
        font-weight: 500;
        color: #89898c;
        cursor: pointer;
        &.ContactUs__button--active {
          color: $background;
        }
      }
    }
  }
  .ContactUs__email {
    display: flex;
    width: 100%;
    height: 90vh;
    box-sizing: border-box;
    padding: 10vh 17.5%;
    align-items: center;
    justify-content: center;
    background-color: $gray0;
    h1 {
      font-family: 'Mohave';
      // font-family: 'Noto Sans';
      font-weight: 500;
      font-size: 8.667rem;
      color: $secondary;
      letter-spacing: -0.2rem;
      margin: 0;
    }
    span {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 2.222rem;
      line-height: 3.333rem;
      white-space: pre-line;
      margin-top: 2rem;
      color: $secondary;
    }
  }
}
.AlertModal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 50;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  &.AlertModal--isOpen {
    display: flex;
  }
  .AlertModal__box {
    display: flex;
    align-items: center;
    background-color: #535359;
    box-sizing: border-box;
    width: 50%;
    padding: 5rem 5% 4rem;
    border: 0;
    border-radius: 1.5rem;
    img {
      height: 7rem;
      object-fit: contain;
    }
    h1 {
      margin: 2rem 0;
      font-family: 'Mohave';
      font-weight: 600;
      font-size: 3.555rem;
      // line-height: 4.444rem;
      letter-spacing: -1.28px;
      color: $background;
    }
    span {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 1.555rem;
      color: $background;
      margin-bottom: 3rem;
    }
    button {
      border: 0;
      background-color: $primary;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 1.555rem;
      padding: 1.5rem 10rem;
      border-radius: 3rem;
      color: $background;
      transition: background-color 150ms linear;
      cursor: pointer;
      &:hover {
        background-color: #5f50b1;
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .ContactUs {
    .ContactUs__header {
      padding: 15vh 5%;
      .ContactUs__box {
        h1 {
          font-size: 7.333rem;
        }
      }
    }
    .ContactUs__contact {
      padding: 5vh 5%;
      .ContactUs__wrap {
        .ContactUs__input {
          width: 100%;
          margin: 0 0 3rem;
        }
      }
      .ContactUs__footer {
        .ContactUs__checkbox {
          text-align: left;
        }
      }
    }
    .ContactUs__location {
      padding: 5vh 5%;
      height: auto;
      h1 {
        margin: 0 0 3rem;
      }
      .ContactUs__list {
        // flex-direction: column;
        .ContactOffice {
          width: 100%;
          .ContactOffice__box {
            padding: 60vw 0 5vh;
            width: 100%;
          }
        }
        .ContactOffice__map {
          top: 15vh;
          right: 5%;
          width: 90%;
          height: 50vw;
        }
      }
      .ContactUs__nav {
        margin: 3vh 0;
        align-items: center;
        button {
          flex: 1;
          padding: 1.5rem 3%;
          text-align: left;
        }
      }
    }
    .ContactUs__email {
      padding: 10vh 5%;
      height: auto;
      h1 {
        font-size: 4.5rem;
        white-space: pre-line;
      }
    }
  }
}
