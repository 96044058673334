@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Mohave:wght@400;500;600;700&family=Montserrat:wght@300;500;600&family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@400;500&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face { font-family: 'DoHyeon'; src: url(./fonts/DoHyeonRegular.ttf) format('truetype'); }
@font-face { font-family: 'Righteous'; src: url(./fonts/RighteousRegular.ttf) format('truetype'); }
@font-face { font-family: 'NexaDemoBold'; src: url(./fonts/NexaDemo-Bold.ttf) format('truetype'); }
@font-face { font-family: 'NexaBold'; src: url(./fonts/Nexa Bold.otf) format('truetype'); }
@font-face { font-family: 'NexaLight'; src: url(./fonts/NexaTextDemo-Light.ttf) format('truetype'); }
