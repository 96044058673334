@import './color.scss';

@keyframes header-open {
  0% {
    height: 10rem;
  }
  100% {
    height: 95vh;
  }
}
@keyframes header-close {
  0% {
    height: 95vh;
  }
  100% {
    height: 10rem;
  }
}
html {
  font-size: 0.8375vw;
}

.column {
  flex-direction: column;
}
.Main {
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden auto;
  .Main__repeat {
    width: 100%;
    font-family: 'Mohave';
    font-weight: 400;
    font-size: 6.667rem;
    letter-spacing: -0.7px;
    color: $background;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    &.Main__repeat--h1 {
      font-size: 27.778rem;
      font-weight: 700;
      color: $white;
    }
    &.Main__repeat--h2 {
      font-size: 16.111rem;
      font-weight: 700;
      color: $white;
    }
  }
}

.CustomOverlay {
  position: relative;
  bottom: 0;
  border: 0;
  float: left;
  top: -200%;
  a {
    display: flex;
    align-items: center;
    background-color: $background;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 0.5rem 1.5rem;
    font-family: 'Mohave';
    font-weight: 600;
    font-size: 1.555rem;
    color: $gray900;
    text-decoration: none;
    transition: all 150ms linear;
    &:hover {
      background-color: $secondary;
      color: $background;
      img {
        content: url('https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowNext__white.webp');
      }
    }
    img {
      object-fit: contain;
      object-position: center;
      width: 1.5rem;
      height: auto;
      margin-left: 1rem;
    }
  }
}
.Header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  z-index: 10;
  padding: 2rem 5%;
  height: 10rem;
  max-height: 10rem;
  align-items: center;
  justify-content: space-between;
  // animation: header-close 200ms ease-in-out;
  transition: height 250ms ease-in-out;
  &.Header--isTop {
    height: 10rem;
    max-height: 10rem;
    .Header__close {
      display: none;
    }
  }
  &.Header--isOpen {
    background-color: $secondary;
    height: 95vh;
    max-height: 95vh;
    // animation: header-open 200ms ease-in-out;
    // align-items: flex-start;
    .Header__close {
      display: flex;
      position: absolute;
      top: -7.5rem;
      right: -19%;
      border: 0;
      background-color: transparent;
      box-sizing: border-box;
      padding: 1rem;
      cursor: pointer;
      img {
        height: 2rem !important;
      }
    }
    .Header__box {
      // flex: 1;
      position: unset;
      box-sizing: border-box;

      // width: 100%;
      // padding: 0 5%;
      .Header__list {
        top: 10rem;
        left: 17vw;
        right: inherit;
        width: 65vw;
        box-sizing: border-box;
        padding: 0 5%;
        .Header__nav button {
          font-size: 5.111rem;
          font-weight: 500;
          padding: 0;
        }
        // position: relative !important;
        // flex: 1;
      }
    }
  }
  .Header__logo {
    position: absolute;
    height: 3rem;
    object-fit: contain;
    left: 5%;
    top: 2.5rem;
    text-decoration: none;
    img {
      object-fit: contain;
      height: 100%;
    }
  }
  .Header__box {
    // position: relative;
    overflow: visible;
    display: flex;
    align-items: center;
    position: absolute;
    right: 5%;
    .Header__list {
      position: absolute;
      right: 5%;
      // top: 5%;
      z-index: 1;
      display: flex;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 0rem 3%;
      width: 10rem;
      .Header__nav {
        display: flex;
        align-items: flex-start;
        transition: all 150ms linear;
        &:hover {
          button {
            color: $primary;
            text-align: left;
            border-color: $primary;
          }
          .children {
            display: flex;
            &:hover {
              color: $primary;
              border-color: $primary;
            }
          }
        }
        button {
          border: 0;
          background-color: transparent;
          border-bottom: 2px solid transparent;
          color: $white;
          font-family: 'Mohave';
          font-weight: 500;
          font-size: 1.555rem;
          padding: 0.7rem 0rem;
          text-transform: uppercase;
          transition: all 150ms linear;
          cursor: pointer;
          &.children {
            display: none;
            color: $white;
            border-color: transparent;
          }
        }
      }
    }
    .Header__menu {
      position: fixed;
      right: 5%;
      top: 2rem;
      background-color: transparent;
      border: 0;
      display: flex;
      width: 5rem;
      height: 5rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 150ms linear;
      &:hover {
        background-color: $primary;
      }
      img {
        height: 1.2rem;
      }
    }
  }
}

.Footer {
  position: relative;
  width: 100%;
  padding: 7vh 17.5%;
  box-sizing: border-box;
  display: flex;
  .Footer__box {
    position: relative;
    width: 100%;
    padding: 5vh 0;
    border-top: 1px solid $gray300;
    border-bottom: 1px solid $gray300;
    h1 {
      font-family: 'Montserrat';
      margin: 0;
      font-weight: 300;
      font-size: 5.555rem;
      color: $white;
      line-height: 6.667rem;
      text-align: left;
      white-space: pre-line;
    }
    .Footer__info {
      display: inline-flex;
      width: 100%;
      margin-top: 2rem;
      font-family: 'Montserrat';
      font-size: 1.333rem;
      font-weight: 300;
      line-height: 2.333rem;
      text-align: left;
      white-space: pre-line;
      color: $lightgray;
    }
    .Footer__nav {
      position: absolute;
      right: 0;
      top: 5vh;
      a {
        color: $white;
        text-decoration: none;
        padding: 1rem 0.7rem;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 1.333rem;
      }
    }
  }
  .Footer__copyright {
    position: absolute;
    bottom: 2.5vh;
    color: $gray300;
    font-weight: 300;
    font-size: 1rem;
  }
  .Footer__logo {
    position: absolute;
    right: 0;
    bottom: 10vh;
    height: 3rem;
  }
}

@media screen and (max-width: 840px) {
  html {
    font-size: 2.3vw;
  }
  .Main__repeat {
    font-size: 6.667rem;
    &.Main__repeat--h1 {
      font-size: 21.778rem !important;
    }
    &.Main__repeat--h2 {
      font-size: 13.111rem !important;
    }
  }
  .Header {
    &.Header--isOpen {
      .Header__box {
        .Header__list {
          left: 5vw;
        }
      }
      .Header__close {
        right: -10vw;
      }
    }
    .Header__logo {
      height: 3.5rem;
    }
    .Header__list {
      width: 80vw !important;
      .Header__nav {
        button {
          font-size: 3rem !important;
          text-align: left;
        }
      }
    }
  }
  .Footer {
    padding: 5vh 5%;
    .Footer__box {
      padding: 10vh 0;
      .Footer__nav {
        top: 2vh;
      }
    }
    .Footer__logo {
      bottom: 3vh;
    }
  }
}
